<template>
  <div id="footer">
    <div class="content"> 
      <div class="content-logos"> 
      </div>
    </div>
    <div class="content">
      <div class="column left">
        <div class="text">
            <p>
                ИП Перейра Анастасия Сергеевна
            </p>
            <p>
                УНП 193234741
            </p>
            <p>
                СВ-ВО О ГОС. РЕГИСТРАЦИИ №193234741 <br>
                ВЫДАНО МИНСКИМ ГОРИСПОЛКОМОМ 02.11.2022 <br>
                220021, РЕСПУБЛИКА БЕЛАРУСЬ, Г.МИНСК, УЛ.Виктора Ливенцева 2-43
            </p>
            <p>
              Время работы: понедельник, вторник, четверг с 12:00-21:00
            </p>
        </div>
      </div>

      <div class="column right">
        <span class="text">
                    <p>
                        +375293824059 Telegram 
                    </p>
                    <br>
                    <p>
                       asjostain@gmail.com
                    </p>
              
         </span>
    </div>
  </div>
</div>
    
</template>

<script>

export default {
  name: 'Footer',
}
</script>

<style scoped lang="scss">
@import "./../style.scss";

#footer {
  margin-top: 60px;
  border-top: 2px solid $graphiteclr;
  display: grid;
  width: 100%;
  bottom: 0;
  grid-template-columns: repeat(12, 1fr);
  /* grid-auto-rows: minmax(100px, auto); */
}

.content {
  grid-column: 3 / 11;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
//  src="../assets/logos-bank.svg" alt="bank logos"
.content-logos {
 grid-column:  1 / 13;
 background-image: url("../assets/logos-bank.svg");
 background-repeat: no-repeat;
background-position: center center;
// background-attachment: fixed;
background-size: contain;
   margin: 0;
   padding-top: 9rem;
   padding-bottom: 2rem;
   display: grid;
}

.column{
  &.left {
  grid-column:  1 / 8;
  grid-row: 1;
  }
  &.right{
    grid-column: 9 / 13;
    grid-row: 1;

    .text {
      text-align: end !important;

    }
  } 
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 550px) {
.column{
  &.left {
  grid-column:  1 / 13;
  grid-row: 1;
  }
  &.right{
    grid-column: 1 / 13;
    grid-row: 2;

    .text {
      text-align: start !important;

      br {
        display: none;
      }
    }
  } 
}

}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {

.content {
    grid-column: 1/13;
    margin: 0 20px;
}

}

</style>