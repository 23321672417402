<template>
<div class="about">
    <div class="title">
        <h1>
                ОБО МНЕ
        </h1>
    </div>

    <div class="content">
        <div class="gallery">
        <!-- <a target="_blank" href="img.jpg"> -->
            <img src="../../assets/img.jpg" alt="Photo" width="400" >
        <!-- </a> -->
        </div>
       
        <div class="text">
            
            <h2 class="subtitle">
                Перейра Анастасия 
            </h2>

            <h3 class="info">
                Психолог, гештальт-терапевт
            </h3>

            <span class="education">
                <h4>
                    Образование и опыт работы: 
                </h4>
                <p class="black-text">
                    Врач психиатр-нарколог, психотерапевт, психолог, гештальт-терапевт.
                </p>
                <p>
                    Опыт работы 7 лет в медицинских учреждения соматического и специализированного профиля.
                </p>
            </span>
        </div>

        <div class="steps">
            <span>
                
                <ul>
                    <h4 class="steps-title">
               Этапы проведения консультации:
                </h4>
                    <li>
                        Выбрать услугу на странице сайта  	
                    </li>
                    <li>
                        Связаться со мной удобным для вас способом
                    </li>

                    <li>
                        Дождаться моего подтверждения, согласования консультации и записи
                    </li>

                    <li>
                        Оплатить услугу
                    </li>

                    <li>
                        Прийти в назначенное время и место
                    </li>
                </ul>
            </span>
        </div>
    </div>    
</div>
</template>

<script>

export default {
  name: 'About',

}
</script>

<style lang='scss' scoped>

@import '../../style.scss';

.black-text {
    color: $blackclr !important;
}

ul {
    display: inline-grid;
    margin-top: 20px;
    padding-bottom: 16px;
    padding-left: 20px;
    width: calc(100% - 20px);
    list-style-type: circle;
}

.steps-title {
   margin-left: 24px;
}

.info {
    margin-top: 40px;
}

.about {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}
.title {
    grid-row: 1;
    grid-column: 3/11;
}
.content {
    grid-row: 2;
    grid-column: 3/11;
}

.steps {
    grid-row: 3;
    grid-column: 3/11;
}
.education {
    padding-top: 120px;
}

div.gallery {
  
  /* border: 1px solid #ccc; */
  
  float: right;
}

li {
    margin: 8px 20px 8px 20px;
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {
    .text {
    padding: 0 20px;
}

    .title {
    grid-row: 1;
    grid-column: 1/13;
}
.content {
    grid-row: 2;
    grid-column: 1/13;
}

.steps {
    grid-row: 3;
    grid-column: 1/13;
}
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 739px) {

    div.gallery {
  
  /* border: 1px solid #ccc; */
  
  float: none;
}
    .gallery {
        display: grid;
        justify-content: center;
    }

}

</style>