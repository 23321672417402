<template>
<div class="oferta">

    <div class="title">
      <h1>
        Информация об оплате
        <!-- Договор публичной оферты -->
      </h1>
    </div>

    <div class="content">
      <h3>Оплата услуг</h3>
     
      <p>
        Оплатить услугу вы можете банковской карточкой через интернет.
      </p>

      <span>
        <ul>
          <h3 class="services-title">
            Для этого вам необходимо:
          </h3>

          <li>
            Заказать услугу, которую вы хотите получить, любым удобным для вас способом:
            <ul>
              <li>
                позвонить по телефону: +375 (29) 382-40-59
              </li>
              <li>
                написать в Telegram: +375 (29) 382-40-59. 
              </li>
            </ul>
          </li>
          
          <li>
            В течение суток я свяжусь с вами, и мы оговорим условия консультации. После чего я вышлю (в мессенджер или e-mail) ссылку для оплаты услуги. Кликнув на ссылку, вы перейдете на специальную защищенную платежную страницу процессинговой системы <a href="https://bepaid.by/" class="link-c" >bePaid</a>.
          </li>
          
          <li>
            На платежной странице будет указан номер заказа и сумма платежа. Для оплаты вам необходимо ввести свои карточные данные и подтвердить платеж, нажав кнопку
          «Оплатить».
          Если ваша карта поддерживает технологию 3-D Secure, системой вам будет предложено пройти стандартную одноминутную процедуру проверки владельца карты на странице вашего банка (банка, который выдал вашу карту).
          </li>
          
          <li>
            После оплаты консультации вам необходимо прийти в назначенное время и место для проведения встречи в оговорённом нами способом.
            Обращаю ваше внимание, что после проведения платежа на указанный вами электронный адрес придет <a href="./pay-example.pdf" class="link-c">подтверждение оплаты</a>. Просим вас сохранять данные оплат.
            Мы принимаем платежи по следующим банковским картам: Visa, Visa Electron, MasterCard, Maestro, Белкарт.
          </li>
        </ul>
      </span>
           
      <p>
        Платежи по банковским картам осуществляются через систему электронных платежей <a href="https://bepaid.by/" class="link-c">bePaid</a>
      </p>
      <p>
        Платежная страница системы bePaid отвечает всем требованиям безопасности передачи данных (PCI DSS Level 1). 
        Все конфиденциальные данные хранятся в зашифрованном виде и максимально устойчивы к взлому. 
       <br>
        Доступ к авторизационным страницам осуществляется с использованием протокола, обеспечивающего безопасную передачу данных в Интернетe (SSL/TLS).
        </p>
      <p> 
        Возврат денежных средств осуществляется на карту, с которой ранее была произведена оплата. Срок поступления денежных средств на карту от 1 до 30 дней с момента осуществления возврата Продавцом.    
        <br>Условия возврата описаны здесь: <a href="./return-money.pdf" class="link-c">условия возврата</a>.
      </p>

      <h3>
        Просмотр договора публичной оферты:
      </h3>
      <p>Для просмотра договора публичной оферты <a href="./oferta.pdf" class="link-c">кликните по ссылке</a> </p>

      <h3>
        Просмотр политики конфиденциальности:
      </h3>
      <p>Для просмотра политики конфиденциальности <a href="./personal_data.pdf" class="link-c">кликните по ссылке</a></p>
      <h3>
        Просмотр правил возврата денежных средств:
      </h3>
      <p>Для просмотра правил возврата денежных средств <a href="./return-money.pdf" class="link-c">кликните по ссылке</a></p>

    </div>
</div>
</template>

<script>

export default {
  name: 'Oferta',
}
</script>

<style lang="scss" scoped>
.oferta {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.title {
    grid-row: 1;
    grid-column: 3/11;
}
.content {
    grid-row: 2;
    grid-column: 3/11;
}
li {
    margin: 8px 20px 8px 20px;
}

.services-title {
    margin: 20px;
}

ul {
    padding-bottom: 16px;
    list-style-type: decimal;
    padding-left: 20px;
    ul {
      padding-bottom: 0;
    }
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {
    .title {
    grid-row: 1;
    grid-column: 1/13;
}
.content {
    grid-row: 2;
    grid-column: 1/13;
    margin: 0 20px;
}

}

</style>