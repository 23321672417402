<template>
  <div class="cost">
      <div class="title">
              <h1>
                СТОИМОСТЬ
              </h1>
          </div>
      <div class="content">

          <span class="card">
                  <ul>
                      <h3 class="cost-title">
                          Индивидуальная консультация
                      </h3>

                      <li>
                          Время: 50 мин
                      </li>
                    
                      <li>
                        Стоимость: 135 рублей
                      </li>

                  <a class="closing-button" id='individualConsultation' :href="payLink.individual" ><span>Оплатить</span></a>
            
                  </ul>
          </span>

          <span class="card2">
                  <ul>
                      <h3 class="cost-title">
                          Консультация пары
                      </h3>

                      <li>
                        Время: 80 минут
                      </li>
                    
                      <li>
                        Стоимость 200 рублей
                      </li>

                      <a class="closing-button" id='familyConsultation' :href="payLink.family"><span>Оплатить</span></a>
                  </ul>
              </span>
      </div>
  </div>
</template>

<script>
import 'vue-universal-modal/dist/index.css' 

export default {
  name: 'Cost',
  data() {
     return {
       isShow: false,
       payLink: {
        // individual: 'https://checkout.bepaid.by/v2/confirm_order/prd_2c3c3c194dd8b13d/17420',
        // family: 'https://checkout.bepaid.by/v2/confirm_order/prd_9bdcaeded59a8326/17420',
        // individual: 'https://checkout.bepaid.by/confirm-page/prd_ea7a2e447e3e6071/17420',
        // family: 'https://checkout.bepaid.by/confirm-page/prd_408e6ec14ff2fc67/17420',
         individual: 'https://checkout.bepaid.by/confirm-page/prd_633dd00c4df7b347/17420',
        family: 'https://checkout.bepaid.by/confirm-page/prd_601774b8dd7bdc92/17420',
       },
    };
  },
  methods: {
    onAlert() {
      this.isShow = true
    },
    closeModal () {
      this.isShow = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../style.scss";

.card {
    grid-row: 1;
    grid-column: 1/6;
    width: 100%;
    margin: 0 10%;

    h3 {
      height: 60px;
    }
    .closing-button {
       margin: 10px 30%;
    }
}
.card2 {
    grid-row: 1;
    grid-column: 7/12;
    width: 100%;
    margin: 0 10%;
     h3 {
      height: 60px;
    }
    .closing-button {
       margin: 10px 30%;
    }
}


.title {
    grid-row: 1;
    grid-column: 3/11;
}
.cost-title {
    margin: 20px;
}

li {
    margin: 8px 20px 8px 20px;
}

ul {
     display: inline-grid;
    padding-bottom: 16px;
    width: 100%;
}

.content {
    grid-row: 2;
    grid-column: 3/11;
    display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.cost {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 845px) {
.card {
    grid-row: 1;
    grid-column: 1/13;
    width: calc(100vw - 40px);
    margin: 0;
    padding: 0 20px 20px 20px;
    .closing-button {
       margin: 10px 20px;
    }
}
.card2 {
    grid-row: 2;
    grid-column: 1/13;
    width: calc(100vw - 40px);
    margin: 0;
    padding: 0 20px 20px 20px;
    .closing-button {
       margin: 10px 20px;
    }
}

}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {
    .title {
    grid-row: 1;
    grid-column: 1/13;
}
.content {
    grid-row: 2;
    grid-column: 1/13;
}

}

.shown {
  visibility: visible !important;
}

.hidden-modal {
  position: absolute;
  margin: 20vh 40%;
  visibility: hidden;
  border: 2px solid $greenclr;
  background-color: $lightgrayclr3;
}

.closing-button {
 
  text-decoration: none;
  display: inline-block;
  color: white;
  box-shadow: 0 0 0 2px white;
  padding: 20px 0;
  width: 150px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  overflow: hidden;
}
.closing-button span {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  z-index: 5;
}
.closing-button:before, .closing-button:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.closing-button:before {
  transform: translateX(-100%);
  background: white;
  transition: transform .3s cubic-bezier(.55, .055, .675, .19);
}
.closing-button:after {
  background: $greenclr;
  transform: translateX(100%);
  transition: transform .3s cubic-bezier(.16, .73, .58, .62) .3s;
}
.closing-button:hover:before, .closing-button:hover:after {
  transform: translateX(0);
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

</style>