<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
<div class="top">
  <!-- <h1 >Перейра Анастасия </h1> -->
  <HeaderComponent class="header">
  </HeaderComponent>

  <!-- <router-view style="position: relative;
  margin-top: 30px;
    height: max-content;
    display: block;" /> -->
  <Landing style="
  margin-top: 60px;
    height: max-content;
    display: block;">
    </Landing>
  
  <footer-component>
  </footer-component>
</div>
</template>

<script>


import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'
import Landing from './components/Landing.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    Landing
  }
}
</script>

<style lang='scss'>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import './style.scss';
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  // background-color: #333;
}
.top {

   /* position: fixed; */
  top: 0;
  width: 100%;
  grid-column: 3/10;
}
</style>
