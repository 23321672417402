<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div >
      <About id="about"></About>
      <Services id="service"></Services>
      <Cost id="cost"></Cost>
      <Oferta id="oferta"></Oferta>
  </div>
</template>

<script>

import Services from "./pages/Services.vue"
import About from "./pages/About.vue"
import Cost from "./pages/Cost.vue"
import Oferta from "./pages/Oferta.vue"

export default {
  name: 'Landing',
  components: {
      About,
      Services,
      Cost,
      Oferta
  }
 
}
</script>

<style scoped lang='scss'>
#about, #service, #cost, #oferta {
  margin-bottom: 100px;
}

</style>
