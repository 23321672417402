import { createWebHistory, createRouter } from "vue-router"; //
//import About from "../../components/pages/About.vue";
//import Services from "../../components/pages/Services.vue";
// import Contacts from "../../components/pages/Contacts.vue";
//import Cost from "../../components/pages/Cost.vue";
//import Oferta from "../../components/pages/Oferta.vue";
import Landing from "../../components/Landing.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Landing,
  },
  // {
  //   path: "/services",
  //   name: "Services",
  //   component: Services,
  // },
  // {
  //   path: "/contacts",
  //   name: "Contacts",
  //   component: Contacts,
  // },
  // {
  //   path: "/cost",
  //   name: "Cost",
  //   component: Cost,
  // },
  // {
  //   path: "/oferta",
  //   name: "Oferta",
  //   component: Oferta,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});



export default router;