<template>
  <div id="nav" class="header">
      <ul>
        <li>
          <a href="/#about" class="pointer"> 
            Обо мне
          </a>

        </li>
        <li> 
          <a href="/#service">
            Услуги
          </a>

        </li>
        <li> 
          <a href="/#cost">
            Стоимость
          </a>
        </li>
        <li> 
          <a href="#oferta">
            Оплата  
          </a>

        </li>
    </ul>
  </div>
</template>

<script>
// этот код меняет поведение прокрутки на "smooth"
window.scrollTo({
    behavior: "smooth"
});
export default {
  name: 'Header',
}
</script>

<style lang='scss' scoped>

@import '../style.scss';

li {
  height: 60px;
  min-height: 60px;
  float: left;
   color: $graphiteclr;
}

li a {
  min-height: 60px;
  display: block;
  color: $graphiteclr;
  font-size: $font-important;
  text-align: center;
  padding: 16px 32px;
  text-decoration: none;
}

li a:hover {
  background-color: $lightgrayclr2;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {

}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 730px) {
  li a {
  font-size: 18px;
  padding: 16px 24px;
}
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 435px) {
  li a {
  font-size: 18px;
  padding: 16px 24px;
}
}

</style>