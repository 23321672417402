<template>
<div>
    <div class="services">
        <div class="title">
            <h1>
                УСЛУГИ
            </h1>
        </div>

        <div class="content">
            

            <span>
                <ul>
                    <h3 class="services-title">
                        Сфера моей деятельности и интересов:
                    </h3>

                    <li>
                        внутриличностные отношения (кто Я есть, каковы мои потребности и желания, отношения со своей внешностью и телесностью, вопросы поиска и обнаружения собственных границ, проживание кризисов жизни и адаптации к ним);
                    </li>
                    <li>
                        межличностные отношения (поиск способов видеть и слышать Другого, учиться строить и развивать взаимодействия, а также проживать завершение или утрату отношений);
                    </li>

                    <li>
                        невозможность осознавать и проживать свои чувства в контакте (с опорой на тело поиск собственных чувств и научение их замечать и называть, поиск способов их выражения);
                    </li>

                    <li>
                        работа с парами (переживание кризисов отношений, конфликты в виду взросления детей, разводов, измен, утрат; созависимые отношения).
                    </li>
                </ul>
            </span>

            <span>
                <p class="centered">
                    В своей практике я работаю в рамках гештальт подхода как индивидуально, так и с парами.
                </p>
            </span>

        </div>
    </div>
</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'Service',
}
</script>

<style lang="scss" scoped>
@import "../../style.scss";

.centered {
    justify-content: center;
    text-align: center;
    font-size: $font-text;
}

li {
    margin: 8px 20px 8px 20px;
}

.services-title {
    margin: 20px;
}

ul {
    padding-bottom: 16px;
    list-style-type: circle;
    padding-left: 20px;
}

.services {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.title {
    grid-row: 1;
    grid-column: 3/11;
}
.content {
    grid-row: 2;
    grid-column: 3/11;
    // float: left;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 1120px) {
    .title {
    grid-row: 1;
    grid-column: 1/13;
}
.content {
    grid-row: 2;
    grid-column: 1/13;
}

}


</style>